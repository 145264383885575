import { render, staticRenderFns } from "./VideoContent1And2.vue?vue&type=template&id=bd14fb30&scoped=true"
import script from "./VideoContent1And2.vue?vue&type=script&lang=js"
export * from "./VideoContent1And2.vue?vue&type=script&lang=js"
import style0 from "./VideoContent1And2.vue?vue&type=style&index=0&id=bd14fb30&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bd14fb30",
  null
  
)

export default component.exports